<template>
    <onboarding-layout
        :loading="loading"
        :loading-title="loadingTitle"
        :error-text="errorText"
    >
        <h3 class="fw-bold mb-1">
            Welcome<span v-if="primaryFirstName"> {{ primaryFirstName }}</span><span v-if="secondaryFirstName"> & {{ secondaryFirstName }}</span>!
        </h3>

        <h5 class="fw-light mb-5">
            What you are looking to do...
        </h5>

        <form-container
            id="statedUsageForm"
            data-testid="stated-usage-form"
            ref="form"
            @on-submit="onSubmit"
        >
            <form-radio-group
                :options="statedUsageOptions"
                v-model="usage"
            />
            <form-field-currency
                v-model="amount"
                data-testid="stated-usage-amount-input"
                placeholder="ie. $20,000"
                name="statedUsageAmount"
                validation-rules="required|currency:1,500000"
                label="What is the max amount you need?"
            />
            <form-button
                type="button"
                data-testid="stated-usage-continue-button"
                label="Continue"
                class="mt-2 mb-1"
                :block="true"
                :submitting="submitting"
                @click="onSubmit"
                event-name="click_button_submit_stated_usage_form"
                :event-props="{ statedUsageAmount: amount, statedUsage: usage }"
            />
        </form-container>
        <safe-credit-score />
        <template #sidebar>
            <ul class="list-unstyled list-row">
                <help-list-item />
            </ul>
        </template>
    </onboarding-layout>
</template>

<script lang="js">
    import OnboardingLayout from "@/layouts/Onboarding"
    import HelpListItem from "@/components/onboarding/HelpListItem"
    import FormButton from "@/components/base/FormButton"
    import FormContainer from "@/components/base/FormContainer"
    import FormFieldCurrency from "@/components/base/FormFieldCurrency"
    import FormRadioGroup from "@/components/base/FormRadioGroup"
    import SafeCreditScore from '@/components/SafeCreditScore'
    import {ApiErrorHandler} from "@/utils/exception-handler"
    import { logger } from '@/utils/logger'
    import {i18n} from "@/utils/i18n"
    import {updateStatedUsage} from "@/services/loanApplication"
    import originationMixin from "@/mixins/originationMixin"
    import {getNextRoute} from "@/flow/flowController"
    import {appSessionStorage, localStorageKey} from "@/utils/storage";

    export default {
        components: {
            'form-field-currency': FormFieldCurrency,
            'form-radio-group': FormRadioGroup,
            'safe-credit-score': SafeCreditScore,
            'help-list-item': HelpListItem,
            "onboarding-layout": OnboardingLayout,
            "form-button": FormButton,
            "form-container": FormContainer,
        },
        data: function() {
            return {
                statedUsageOptions: [i18n.t('pages.origination.statedUsage.usageOne'), i18n.t('pages.origination.statedUsage.usageBt'), i18n.t('pages.origination.statedUsage.usageOther')],
                usage: '',
                amount: '',
            }
        },
        mixins: [originationMixin],
        mounted: function() {
            if (!appSessionStorage.getItem(localStorageKey.phoneNumber)) {
                logger.warn(`User hit stated usage page without a phone number! Redirecting to landing page so they can supply one`)
                appSessionStorage.clear()
                window.location.href = '/'
                return
            }
            this.$logEvent('view_stated_usage')
        },
        methods: {
            onSubmit: async function() {
                this.submitting = true

                // Check if form is valid
                const isValid = await this.$refs.form.$refs.observer.validate()
                if (!isValid || isNaN(parseInt(this.amount))) {
                    this.submitting = false
                    return
                }

                try {
                    logger.info(`Sending stated Usage - amount: ${this.amount} | usage: ${this.usage}`)

                    appSessionStorage.setItem(localStorageKey.statedUsage, this.usage)

                    await updateStatedUsage(this.amount, this.usage)

                    return await this.$router.push(getNextRoute(this.$router))
                } catch (error) {
                    this.errorText = ApiErrorHandler(error)
                }
                this.submitting = false
            },
        }
    }
</script>
