<template>
    <onboarding-layout
        :loading="loading"
        :loading-title="loadingTitle"
        :error-text="errorText"
    >
        <h5
            class="fw-light mb-5"
            v-html="title"
        />

        <div class="d-grid">
            <button
                class="btn btn-primary mb-2"
                @click="handleItsMeClick"
            >
                {{ $t('pages.origination.remedyNameMismatch.positiveCta') }}
            </button>

            <button
                class="btn btn-secondary mb-2"
                @click="handleItsNotMeClick"
            >
                {{ $t('pages.origination.remedyNameMismatch.negativeCta') }}
            </button>
        </div>

        <template #sidebar>
            <ul class="list-unstyled list-row">
                <help-list-item />
            </ul>
        </template>
    </onboarding-layout>
</template>

<script lang="js">
    import OnboardingLayout from "@/layouts/Onboarding"
    import HelpListItem from "@/components/onboarding/HelpListItem"
    import {i18n} from "@/utils/i18n"
    import { sharedPagePaths } from "@/routes/sharedRoutes";
    import { getNonEntityOwnerNames } from "@/services/offer";
    import { logger } from "@/utils/logger";
    import { updateApplicantNameFromNonEntityOwnerName } from "@/services/api";
    import { getNextRoute } from "@/flow/flowController";
    import { appSessionStorage, localStorageKey } from "@/utils/storage";
    import startCase from 'lodash/startCase'
    import toLower from 'lodash/toLower'

    export default {
        components: {
            'help-list-item': HelpListItem,
            'onboarding-layout': OnboardingLayout,
        },
        data: function() {
            return {
                loading: true,
                loadingTitle: 'Loading...',
                errorText: '',
                submitting: false,
                ownerNameChoiceIndex: 0,
                ownerNameChoices: [],
                currentOwnerNameToConsider: null,
            }
        },
        computed: {
            title() {
                return i18n.t('pages.origination.remedyNameMismatch.title', { ownerName: startCase(toLower(this.currentOwnerNameToConsider)) })
            }
        },
        mounted: async function() {
            try {
                // "non-entity" in this case means non-trust and non-LLC. These names will form the
                // options for which names the applicant can choose from.
                logger.info('Making request for non-entity owner names to remedy name mismatch')
                const nonEntityOwnerNamesResponse = await getNonEntityOwnerNames()
                this.ownerNameChoices = nonEntityOwnerNamesResponse.data.payload?.ownerNames ?? []
                if (nonEntityOwnerNamesResponse.data.success && this.ownerNameChoices.length > 0) {
                    logger.info(`Got ${this.ownerNameChoices.length} owner names to choose from ${JSON.stringify(this.ownerNameChoices)}`)
                    this.setCurrentOwnerNameChoice(this.ownerNameChoiceIndex)
                } else {
                    logger.info(`Getting non-entity owner names ${nonEntityOwnerNamesResponse.data.success ? 'returned no names to choose from' : `was not successful. Error ${nonEntityOwnerNamesResponse.data.error}`}`)
                    await this.navigateToThanks()
                }
                this.$logEvent('view_remedy_name_mismatch', { ownerNameChoices: this.ownerNameChoices })
            } catch (e) {
                logger.error(`Couldn't get non-entity owner names to remedy name mismatch.`, null /* event */, e)
                await this.navigateToThanks()
            } finally {
                this.loading = false
            }
        },
        methods: {
            setCurrentOwnerNameChoice: function (ownerNameIndex) {
                const ownerName = this.ownerNameChoices[ownerNameIndex]
                logger.info(`Setting current owner name choice to ${JSON.stringify(ownerName)} at index ${ownerNameIndex}`)
                this.currentOwnerNameToConsider = `${ownerName.first} ${ownerName.last}`
            },
            handleItsMeClick: async function() {
                try {
                    this.$logEvent('click_button_update_name_to_owner_name', { selectedName: this.currentOwnerNameToConsider })
                    logger.info(`Applicant says their name is ${this.currentOwnerNameToConsider}`)
                    this.submitting = true
                    const selectedOwnerName = this.ownerNameChoices[this.ownerNameChoiceIndex]
                    const response = await updateApplicantNameFromNonEntityOwnerName(selectedOwnerName)
                    if (response.data.success) {
                        logger.info(`Successfully updated applicant's name to ${JSON.stringify(selectedOwnerName)}. Storing locally and navigating to next route`)
                        // Since the applicant has updated their name, we need to store the new name locally
                        // for use elsewhere during origination.
                        appSessionStorage.setItem(localStorageKey.firstName, selectedOwnerName.first)
                        appSessionStorage.setItem(localStorageKey.lastName, selectedOwnerName.last)
                        await this.$router.replace(getNextRoute(this.$router))
                    } else {
                        logger.error(`Failed to update applicant's name from owner name ${JSON.stringify(this.ownerNameChoices[this.ownerNameChoiceIndex])}. Error: ${response.data.error}`)
                        await this.navigateToThanks()
                    }
                } catch (e) {
                    logger.error(`Couldn't update applicant's name from owner name ${JSON.stringify(this.ownerNameChoices[this.ownerNameChoiceIndex])}`, null, e)
                    await this.navigateToThanks()
                } finally {
                    this.submitting = false
                }
            },
            handleItsNotMeClick: async function() {
                this.$logEvent('click_button_do_not_update_name_to_owner_name', { consideredName: this.currentOwnerNameToConsider })
                logger.info(`Applicant says they are not ${this.currentOwnerNameToConsider}`)
                // If we've already showed the applicant the last owner name choice, and they've
                // responded that it isn't them, then navigate to the thanks page.
                if (this.ownerNameChoiceIndex === this.ownerNameChoices.length - 1) {
                    return await this.navigateToThanks()
                }
                // Otherwise present the next possible owner name choice.
                // Todo Make it obvious that the name option changed so the applicant knows
                //  they have to make another choice.
                else {
                    this.setCurrentOwnerNameChoice(++this.ownerNameChoiceIndex)
                }
            },
            navigateToThanks: async function() {
                await this.$router.push({ path: sharedPagePaths.THANKS, query: { reason: 'homeOwnership' } })
            }
        }
    }
</script>
