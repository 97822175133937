<template>
    <onboarding-layout
        :loading="loading"
        :loading-title="loadingTitle"
        :error-text="errorText"
    >
        <form-container
            class="border rounded-3 bg-gradient text-white"
            v-if="creditOffer"
        >
            <div class="m-3">
                <h3
                    class="mt-3 mb-1"
                    v-html="$t('pages.origination.offerPreview.title', { firstName })"
                />
                <img
                    src="@/assets/images/pages/marketing/crypto/aven-credit-card-reader.png"
                    width="100%"
                    alt="Aven Card Reader"
                >
                <div class="mt-1">
                    <div class="col-12 d-flex justify-content-between">
                        <div class="d-flex align-items-center">
                            <p class="mb-0">
                                Lines Up To
                            </p>
                        </div>
                        <h4 class="d-flex align-items-center mb-0">
                            {{ toFormattedUSDStripTrailingZeroCents(this.maxLineSizeCrypto) }}
                        </h4>
                    </div>
                </div>
                <hr class="my-1">
                <div>
                    <div class="col-12 d-flex justify-content-between">
                        <div class="d-flex align-items-center">
                            <p class="mb-0">
                                Variable APR<sup>1</sup>
                            </p>
                        </div>
                        <h4 class="d-flex align-items-center mb-0">
                            {{ formatApr(this.creditOffer.apr) }}
                        </h4>
                    </div>
                </div>
                <hr class="my-1">
                <div class="mb-3">
                    <div class="col-12 d-flex justify-content-between">
                        <div class="d-flex align-items-center">
                            <p class="mb-0">
                                Unlimited Cashback<sup>1</sup>
                            </p>
                        </div>
                        <h4 class="d-flex align-items-center mb-0">
                            1.5%
                        </h4>
                    </div>
                </div>

                <div class="text-center mt-2 mb-3">
                    <a href="/docs/AccountAgreement_v.1.0.pdf">Important Terms & Conditions</a>
                </div>

                <form-button
                    class="mt-2"
                    button-classes="btn btn-light mb-1"
                    data-testid="offer-continue-button"
                    @click="acceptOffer()"
                    :submitting="submitting"
                    event-name="click_crypto_button_offer_continue"
                >
                    {{ $t('pages.origination.offerPreview.getYourCard') }}
                </form-button>
            </div>
        </form-container>
        <div class="d-none d-md-block small text-muted text-start mt-2">
            <p
                class="mt-2 text-muted"
                v-html="$t('pages.origination.offerPreview.fees')"
            />
            <p>
                {{ $t('pages.origination.offerPreview.hardpull') }}
            </p>
        </div>
        <template #sidebar>
            <ul class="list-unstyled list-row">
                <help-list-item />
            </ul>
            <div class="d-block d-md-none small text-start mt-3">
                <p
                    class="mt-2 text-muted"
                    v-html="$t('pages.origination.offerPreview.fees')"
                />
                <p
                    class="text-muted text-start mb-2"
                    v-html="$t('pages.origination.offerPreview.hardpull')"
                />
            </div>
        </template>
    </onboarding-layout>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import { i18n } from '@/utils/i18n'
    import { Flows, getNextRoute } from '@/flow/flowController'
    import originationMixin from '@/mixins/originationMixin'
    import { logger } from '@/utils/logger'
    import softPullMixin, { SoftPullResult } from '@/mixins/softPullMixin'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { getDocumentForApplicantAndOpen } from '@/utils/document'
    import HelpListItem from '@/components/onboarding/HelpListItem'
    import FormContainer from '@/components/base/FormContainer'
    import FormButton from '@/components/base/FormButton'
    import format from '@/mixins/format'
    import { sharedPagePaths } from '@/routes/sharedRoutes'
    import { RouteOption } from '@/flow/flowUtility'
    import { acceptCryptoOffer, CryptoOfferFailureCode, getAvenCryptoCardOffer, runAvenCryptoCardOffer } from '@/services/offer'
    import { runWithRetryLogic } from '@/utils/http-client'
    import { DateTime } from 'luxon'
    import { LegalDocumentTypes } from '@/services/api'
    import { MAX_LINE_SIZE_CRYPTO } from '@/utils/constants'
    import confetti from 'canvas-confetti'

    export default {
        components: {
            FormContainer,
            FormButton,
            OnboardingLayout,
            HelpListItem,
        },
        mixins: [originationMixin, softPullMixin, format],
        data: function () {
            return {
                loading: true,
                loadingTitle: i18n.t('offerPreview.loading'),
                creditOffer: null,
                maxLineSizeCrypto: MAX_LINE_SIZE_CRYPTO,
            }
        },
        mounted: async function () {
            appSessionStorage.setItem(localStorageKey.currentFlow, Flows.origination)

            const softPullResult = await this.softPullApplicant()
            switch (softPullResult) {
                case SoftPullResult.success:
                    logger.log('Softpull Success!')
                    break
                case SoftPullResult.fail:
                    logger.warn('Soft pull fetch did not appear to complete, dropping into review')
                    appSessionStorage.setItem(localStorageKey.preQualificationFailureCode, 'humanInvestigate')
                    await this.$router.push({ path: sharedPagePaths.THANKS, query: { reason: 'review' } })
                    return
                case SoftPullResult.experianNotFound:
                    logger.warn('Experian not found, dropping into review')
                    appSessionStorage.setItem(localStorageKey.preQualificationFailureCode, 'humanInvestigate')
                    await this.$router.push({ path: sharedPagePaths.THANKS, query: { reason: 'review' } })
                    return
                case SoftPullResult.experianFrozen:
                    await this.$router.replace(getNextRoute(this.$router, RouteOption.experianFrozen))
                    return
                case SoftPullResult.isIneligible:
                    logger.warn('Loan application is ineligible')
                    appSessionStorage.setItem(localStorageKey.preQualificationFailureCode, 'humanInvestigate')
                    await this.$router.push({ path: sharedPagePaths.THANKS, query: { reason: 'default' } })
                    return
                default:
                    logger.error(`Unexpected dataFetchResult in origination flow: ${softPullResult}`)
                    await this.$router.push({ path: sharedPagePaths.THANKS, query: { reason: 'review' } })
                    return
            }

            await this.getOffer()
        },
        methods: {
            getOffer: async function () {
                try {
                    this.loading = true
                    logger.log('Attempting to retrieve final aven crypto card offer (if any)...')

                    const startResponse = await runAvenCryptoCardOffer()
                    const createdAfter = startResponse.data.payload.createdAfter

                    let response
                    for (let i = 0; i < 30; i++) {
                        try {
                            response = await runWithRetryLogic(async () => await getAvenCryptoCardOffer(createdAfter), 1)
                            break
                        } catch (e) {
                            if (e?.response?.status === 423) {
                                // HTTP 423 === "Locked"
                                logger.info(`Waiting on crypto underwriting to complete. Attempt nr ${i + 1}`)
                            } else {
                                throw e
                            }
                        }
                        await new Promise((r) => setTimeout(r, 2000))
                    }

                    if (!response?.data?.success) {
                        logger.info('Someone needs to be manually reviewed!')
                        await this.handleOfferFailureCode(response?.data?.error)
                    } else {
                        const payload = response.data.payload

                        this.creditOffer = payload.offerTerms
                        appSessionStorage.setItem(localStorageKey.creditOffer, JSON.stringify(this.creditOffer))
                        if (this.creditOffer.lineSize !== 0) {
                            throw new Error(`Applicant has a non-zero line size: ${this.creditOffer.lineSize}`)
                        }

                        logger.info(`Credit Offer: ${JSON.stringify(this.creditOffer)}`)

                        // These are the dynamically generated legal docs the applicant can view on this page.
                        const docsToGenerate = [LegalDocumentTypes.accountAgreementCrypto, LegalDocumentTypes.creditScoreDisclosure]
                        const generatedDocsResults = await this.generateAndSaveLegalDocuments(docsToGenerate)
                        if (generatedDocsResults.data.payload.failures.length) {
                            throw new Error(`Not all legal documents could be generated: ${JSON.stringify(generatedDocsResults)}`)
                        }

                        this.loading = false // Only stop loading if the offer goes through /and/ all documents are generated
                        this.$logEvent('view_crypto_offer_preview')

                        // start the sign on bonus countdown timer based on when the loan app offer was initially "offered"
                        this.userSeenOfferTime = DateTime.fromISO(payload.offerTime).toJSDate()

                        // in the form of a confetti explosion!
                        confetti({
                            particleCount: 100,
                            gravity: 0.75,
                            colors: ['#D5208E', '#0054D5'],
                        })
                    }
                } catch (error) {
                    this.errorText = ApiErrorHandler(error)
                }
            },
            handleOfferFailureCode: async function (failureCode) {
                logger.info(`Received offer failure code of ${failureCode}`)
                switch (failureCode) {
                    case CryptoOfferFailureCode.humanInvestigate:
                        await this.$router.push({ path: sharedPagePaths.THANKS, query: { reason: 'review' } })
                        return
                    case CryptoOfferFailureCode.expired:
                        await this.$router.push({ path: sharedPagePaths.THANKS, query: { reason: 'expired' } })
                        return
                    case CryptoOfferFailureCode.denied:
                        await this.$router.push({ path: sharedPagePaths.LOAN_NOT_APPROVED })
                        return
                    default:
                        logger.error(`Unknown failure code: ${failureCode}`)
                        await this.$router.push({ path: sharedPagePaths.THANKS, query: { reason: 'review' } })
                        return
                }
            },
            acceptOffer: async function () {
                if (this.submitting) {
                    logger.log('Already submitting, ignoring button click')
                    return
                }

                try {
                    this.loadingTitle = 'Loading...'
                    this.submitting = true
                    logger.log('User accepted crypto card offer, moving to next route')
                    await acceptCryptoOffer()
                    await this.$router.push(getNextRoute(this.$router))
                } catch (e) {
                    this.errorText = ApiErrorHandler(e)
                } finally {
                    this.loadingTitle = i18n.t('offerPreview.loading')
                    this.submitting = false
                }
            },
            getDocument: async function (docType) {
                try {
                    this.loadingTitle = 'Loading Document...'
                    this.loading = true
                    this.$logEvent('click_crypto_link_get_document', { docType })
                    await getDocumentForApplicantAndOpen(docType)
                } catch (e) {
                    logger.error(`failed to open pdfs document`, null /* event */, e)
                    this.errorText = ApiErrorHandler(e)
                }
                this.loading = false
                this.loadingTitle = i18n.t('offerPreview.loading')
            },
        },
    }
</script>
