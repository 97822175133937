<template>
    <onboarding-layout
        :title="title"
        :sub-title="subTitle"
        :error-text="errorText"
    >
        <upload-button
            class="mb-2"
            title="Bank Statement 1"
            @on-file-change="uploadStatement($event, 1)"
            :is-complete="hasDocumentUploaded(1)"
            :is-submitting="isDocumentUploading(1)"
            :disabled="!!currentlyUploadingIndex"
            incomplete-text="Add"
            complete-text="✓ Added"
            accept="image/*,application/pdf"
        />
        <upload-button
            class="mb-2"
            title="Bank Statement 2"
            @on-file-change="uploadStatement($event, 2)"
            :is-complete="hasDocumentUploaded(2)"
            :is-submitting="isDocumentUploading(2)"
            :disabled="!!currentlyUploadingIndex"
            incomplete-text="Add"
            complete-text="✓ Added"
            accept="image/*,application/pdf"
        />
        <upload-button
            class="mb-2"
            title="Bank Statement 3"
            @on-file-change="uploadStatement($event, 3)"
            :is-complete="hasDocumentUploaded(3)"
            :is-submitting="isDocumentUploading(3)"
            :disabled="!!currentlyUploadingIndex"
            incomplete-text="Add"
            complete-text="✓ Added"
            accept="image/*,application/pdf"
        />
        <form-button
            v-if="areStatementsUploaded"
            type="button"
            label="Continue"
            class="mt-2"
            :block="true"
            @click="next"
            :submitting="submitting"
            event-name="click_button_pay_stub_verify_next"
        />

        <template #sidebar>
            <offer-info-card
                v-if="preQualificationOffer"
                title="Your PreQual Offer"
                :apr="preQualificationOffer.apr"
                :credit-limit="preQualificationOffer.lineSize"
            />
            <ul class="list-unstyled list-row">
                <help-list-item />
            </ul>
        </template>
    </onboarding-layout>
</template>
<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import originationMixin from '@/mixins/originationMixin'
    import { mixins } from '@/mixins/mixins'
    import { i18n } from '@/utils/i18n'
    import FormButton from '@/components/base/FormButton'
    import UploadButton from '@/components/UploadButton'
    import { getNextRoute } from '@/flow/flowController'
    import { beginPayStubVerification, uploadDocument } from '@/services/api'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { logger } from '@/utils/logger'
    import incomeVerificationMixin from '@/mixins/incomeVerificationMixin'
    import HelpListItem from '@/components/onboarding/HelpListItem'
    import OfferInfoCard from '@/components/onboarding/OfferInfoCard'
    import computePreQualificationOfferFromStorageMixin from '@/mixins/computePreQualificationOfferFromStorageMixin'

    export default {
        components: {
            'onboarding-layout': OnboardingLayout,
            'form-button': FormButton,
            'upload-button': UploadButton,
            'help-list-item': HelpListItem,
            'offer-info-card': OfferInfoCard,
        },
        mixins: [mixins, originationMixin, incomeVerificationMixin, computePreQualificationOfferFromStorageMixin],
        computed: {
            title() {
                return i18n.t('bankStatementsUpload.title')
            },
            subTitle() {
                return this.$route.query.verificationInProgress ? i18n.t('pages.shared.incomeVerification.verificationInProgress', { uploadedDocumentDescriptor: 'your bank statements' }) : null
            },
            areStatementsUploaded() {
                return this.uploadFileList.every((v) => !!v)
            },
        },
        mounted: function () {
            let previousUploadInfo = appSessionStorage.getItem(localStorageKey.bankStatementsInfo)
            if (previousUploadInfo) {
                this.uploadFileList = JSON.parse(previousUploadInfo).map((info) => !!info)
            } else {
                appSessionStorage.setItem(localStorageKey.bankStatementsInfo, JSON.stringify([null, null, null]))
                this.uploadFileList = [null, null, null]
            }

            this.$logEvent('view_pay_stub_verification')
        },
        methods: {
            uploadStatement: async function (fileList, index) {
                this.$logEvent('click_button_upload_bank_statement', { statementIndex: index })
                this.errorText = ''

                try {
                    this.currentlyUploadingIndex = index
                    const file = fileList[0]
                    if (!file) {
                        logger.warn('Pay stub file was not selected!')
                        return
                    }
                    const statementFileInfo = this.getFileInfo(file)
                    const previousFileUploads = JSON.parse(appSessionStorage.getItem(localStorageKey.bankStatementsInfo)) || [null, null, null]

                    // check if same file have already been uploaded
                    if (previousFileUploads.includes(statementFileInfo)) {
                        this.errorText = 'That statement was uploaded already. Please upload a different one'
                        return
                    }

                    const response = await uploadDocument(file, 'bankStatement', index, this.isCoApplicantVerifying)
                    if (response.data.error === 'ERROR_UNSUPPORTED_DOCUMENT_TYPE') {
                        this.errorText = i18n.tc('global.errors.unsupportedFileError')
                        return
                    }
                    previousFileUploads[index - 1] = statementFileInfo
                    appSessionStorage.setItem(localStorageKey.bankStatementsInfo, JSON.stringify(previousFileUploads))
                    this.uploadFileList = previousFileUploads // triggers the computed property to update
                } catch (error) {
                    logger.error(`Failed to upload bank statement`, null /* event */, error)
                    this.errorText = i18n.tc('bankStatementsUpload.errorText')
                } finally {
                    this.currentlyUploadingIndex = null
                }
            },
            next: async function () {
                if (this.submitting) return
                this.submitting = true
                this.errorText = ''

                try {
                    await beginPayStubVerification(this.isCoApplicantVerifying)
                    appSessionStorage.setItem(localStorageKey.incomeVerificationCompleted, 'true')
                    return await this.$router.push(getNextRoute(this.$router))
                } catch (error) {
                    logger.error(`Failed to begin paystub verification`, null /* event */, error)
                    this.errorText = ApiErrorHandler(error)
                    this.submitting = false
                }
            },
        },
    }
</script>
