var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-provider',{ref:"provider",staticClass:"form-floating",attrs:{"mode":"eager","vid":_vm.name,"rules":_vm.validationRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'is-invalid': errors[0], 'is-valid': _vm.valid },attrs:{"autocomplete":"new-password","options":_vm.filteredData,"filterable":false,"id":_vm.name,"name":_vm.name,"placeholder":_vm.placeholder,"loading":_vm.loading,"no-drop":_vm.noDrop},on:{"search":_vm.onSearchUpdate},scopedSlots:_vm._u([{key:"spinner",fn:function(ref){
var loading = ref.loading;
return [(loading)?_c('div',{staticClass:"vs__spinner spinner-border spinner-border-sm"}):_vm._e()]}},{key:"selected-option",fn:function(data){return [_c('div',[_c('span',{staticClass:"selected-address"},[_vm._v(_vm._s(data.label))])])]}},{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({ref:"searchInput",staticClass:"vs__search",on:{"input":function($event){return _vm.$emit('input-value-changed', $event.target.value)}}},'input',attributes,false),events))]}},(_vm.noResultsFooterText)?{key:"list-footer",fn:function(){return [_c('div',{on:{"click":_vm.onNoResultsFooterClicked}},[_c('p',{staticClass:"my-1 text-info text-center"},[_vm._v(" "+_vm._s(_vm.noResultsFooterText)+" ")])])]},proxy:true}:null,{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [(searching && !_vm.noDrop)?[_vm._v(" "+_vm._s(_vm.$t('components.formSelectAutocomplete.noOptions'))+" "),_c('em',[_vm._v(_vm._s(search))]),_vm._v(". ")]:_vm._e()]}}],null,true),model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}}),(_vm.label)?_c('label',{attrs:{"for":_vm.name}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }