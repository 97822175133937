<template>
    <onboarding-layout
        :loading="loading"
        :loading-title="loadingTitle"
        :error-text="errorText"
        ref="onboardingLayout"
    >
        <h3 class="fw-bold mb-1">
            Welcome!
        </h3>
        <h5
            class="fw-light mb-5"
            v-html="title"
        />
        <form-container
            id="applicationForm"
            ref="basicInfoForm"
            @on-submit="onSubmit"
            @onError="onError"
        >
            <div class="row g-2 mb-2">
                <div class="col">
                    <form-field
                        v-model="firstName"
                        data-testid="personal-info-first-name-input"
                        name="firstName"
                        :placeholder="$t('components.applicationForm.placeholder.firstName')"
                        :label="$t('components.applicationForm.placeholder.firstName')"
                        validation-rules="required|min:2"
                        autocomplete="given-name"
                    />
                </div>
                <div class="col">
                    <form-field
                        v-model="lastName"
                        data-testid="personal-info-last-name-input"
                        name="lastName"
                        :placeholder="$t('components.applicationForm.placeholder.lastName')"
                        :label="$t('components.applicationForm.placeholder.lastName')"
                        validation-rules="required|min:2"
                        autocomplete="family-name"
                    />
                </div>
                <span
                    class="small text-gray-400 mt-1"
                    v-show="firstName || lastName"
                >Use your legal name from a valid state ID or passport</span>
            </div>

            <form-field
                class="mb-2"
                v-model="email"
                data-testid="personal-info-email-input"
                name="email"
                :placeholder="$t('components.applicationForm.placeholder.email')"
                :label="$t('components.applicationForm.placeholder.email')"
                validation-rules="required|email"
                input-type="email"
                inputmode="email"
            />

            <form-field-ssn
                v-model="ssn"
                data-testid="personal-info-last-4-ssn-input"
                name="ssn"
                class="mb-2"
                last-four-only
            />

            <form-field-date
                class="mb-2"
                v-model="dob"
                data-testid="personal-info-dob-input"
                name="dob"
                validation-rules="required|dateRule|ageRule"
                :label="$t('components.applicationForm.placeholder.dateOfBirth')"
                :placeholder="$t('components.applicationForm.placeholder.dateOfBirth')"
                :focus-placeholder="$t('components.applicationForm.placeholder.dob')"
            />

            <section-header class="mt-4">
                Address
            </section-header>

            <form-field-address
                ref="addressField"
                @on-change="onAddressInputChange"
                @on-clear="onClearAddress"
                initial-input-type="auto"
            />

            <section-header class="mt-4">
                Annual Income
            </section-header>

            <form-field-currency
                v-model="statedIncome"
                data-testid="personal-info-stated-income-input"
                :placeholder="$t('pages.origination.statedIncome.placeholder')"
                :label="$t('pages.origination.statedIncome.placeholder')"
                name="statedIncome"
                validation-rules="required|currency:20000"
            />

            <form-button
                class="mt-4"
                data-testid="personal-info-continue-button"
                image-src="global/web_16_lock.svg"
                :label="buttonCta"
                :submitting="submitting"
                type="submit"
                event-name="click_crypto_button_submit_personal_info_form"
            />

            <safe-credit-score class="mt-1 mb-0" />

            <div class="d-none d-md-block text-start mt-3">
                <p
                    class="text-muted small text-start mb-2"
                    v-html="$t('components.applicationForm.legal.softPullHtml')"
                />
                <p
                    class="text-start text-muted mb-2 small"
                    v-html="$t('pages.origination.personalInfo.pricingTerms')"
                />
                <p
                    class="text-start text-muted mb-2 small"
                    v-html="$t('pages.origination.ssnVerification.legalText')"
                />
            </div>
        </form-container>
        <template #sidebar>
            <ul class="list-unstyled list-row">
                <help-list-item />
            </ul>
            <div class="d-block d-md-none text-start mt-3">
                <p
                    class="text-muted small text-start mb-2"
                    v-html="$t('components.applicationForm.legal.softPullHtml')"
                />
                <p
                    class="text-start text-muted mb-2 small"
                    v-html="$t('pages.origination.personalInfo.pricingTerms')"
                />
                <p
                    class="text-start text-muted mb-2 small"
                    v-html="$t('pages.origination.ssnVerification.legalText')"
                />
            </div>
        </template>
    </onboarding-layout>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import format from '@/mixins/format'
    import originationMixin from '@/mixins/originationMixin'
    import personalInfoMixin from '@/mixins/personalInfoMixin'
    import { logger } from '@/utils/logger'
    import { i18n } from '@/utils/i18n'
    import { localStorageKey, appSessionStorage } from '@/utils/storage'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { getNextRoute } from '@/flow/flowController'
    import FormContainer from '@/components/base/FormContainer'
    import SectionHeader from '@/components/SectionHeader'
    import FormField from '@/components/base/FormField'
    import FormFieldDate from '@/components/base/FormFieldDate'
    import FormFieldSsn from '@/components/base/FormFieldSsn'
    import FormFieldAddress from '@/components/base/FormFieldAddress'
    import FormButton from '@/components/base/FormButton'
    import FormFieldCurrency from '@/components/base/FormFieldCurrency'
    import { experimentsMixin } from '@/mixins/experimentsMixin'
    import { getDocumentForApplicantAndOpen } from '@/utils/document'
    import HelpListItem from '@/components/onboarding/HelpListItem'
    import SafeCreditScore from '@/components/SafeCreditScore'
    import authMixin from '@/mixins/authMixin'
    import { LegalDocumentTypes } from '@/services/api'
    import LogRocket from 'logrocket'
    import { updateStatedIncome } from '@/services/loanApplication'

    export default {
        components: {
            SafeCreditScore,
            HelpListItem,
            FormButton,
            FormFieldDate,
            FormFieldAddress,
            FormField,
            FormFieldCurrency,
            SectionHeader,
            FormContainer,
            FormFieldSsn,
            OnboardingLayout,
        },
        mixins: [format, originationMixin, personalInfoMixin, experimentsMixin, authMixin],
        data() {
            return {
                LegalDocumentTypes,
                loadingTitle: i18n.t('global.loadingMessage.verifying'),
                buttonCta: i18n.t('global.cta.continue'),
                addressData: null,
                email: '',
                ssn: '',
                dob: '',
                statedIncome: '',
            }
        },
        computed: {
            title() {
                return i18n.t('pages.origination.personalInfo.title')
            },
        },
        mounted: async function () {
            // These are the dynamically generated legal docs the applicant can view on this page.
            const docsToGenerate = [LegalDocumentTypes.pricingAndTerms]
            // Purposefully not awaiting the results here because these docs are generated for
            // our record-keeping purposes and not strictly required for the UI
            // noinspection ES6MissingAwait
            this.tryGenerateAndSaveLegalDocuments(docsToGenerate)
            this.$logEvent('view_crypto_personal_info')
        },
        methods: {
            onError(errorText) {
                this.errorText = errorText
                this.scrollToTop()
            },
            scrollToTop() {
                document.body.scrollTop = 0 // For Safari
                document.documentElement.scrollTop = 0
            },
            onAddressInputChange(value) {
                logger.log(`address input change: ${value}`)
                this.addressData = value
            },
            onClearAddress() {
                this.addressData = null
            },
            onSubmit: async function () {
                this.loading = true

                // Check if base is valid
                let isValid = await this.$refs.basicInfoForm.$refs.observer.validate()
                if (this.$refs.addressField.useAutocomplete) {
                    isValid &= this.$refs.addressField.validateAutocompleteAddress()
                }

                if (!isValid || !this.addressData) {
                    // 1. scroll validation errors into view
                    const el = document.querySelector('.is-invalid:first-of-type')
                    el?.scrollIntoView()

                    if (this.errorText) {
                        // 2. if there's errorText, scroll to the top
                        this.scrollToTop()
                    }

                    this.loading = false
                    return
                }

                this.saveDataToStorage()
                try {
                    const submittedBasicInfo = await this.submitBasicInfo({
                        firstName: this.firstName,
                        lastName: this.lastName,
                        emailAddress: this.email,
                        personalAddressData: this.addressData,
                        ssn: this.ssn,
                        applicantDOB: this.dob,
                    })
                    const submittedStatedIncome = await updateStatedIncome(this.statedIncome)

                    if (!submittedBasicInfo || !submittedStatedIncome) {
                        return
                    }

                    this.identifyLogRocketUser()

                    await this.$router.push(getNextRoute(this.$router))
                    return
                } catch (e) {
                    this.errorText = ApiErrorHandler(e)
                } finally {
                    this.loading = false
                }
            },
            saveDataToStorage: function () {
                appSessionStorage.setItem(localStorageKey.firstName, this.firstName)
                appSessionStorage.setItem(localStorageKey.lastName, this.lastName)
                appSessionStorage.setItem(localStorageKey.applicantEmail, this.email)
                appSessionStorage.setItem(localStorageKey.dateOfBirth, this.dob)

                const basicInfo = {
                    addressData: this.addressData,
                }
                appSessionStorage.setItem(localStorageKey.basicInfo, JSON.stringify(basicInfo))
            },
            updateBasicInfo: function (value) {
                this.firstName = value.firstName || this.firstName
                this.lastName = value.lastName || this.lastName
                this.dob = value.applicantDOB || this.dob
                this.email = value.emailAddress || this.email
                this.statedIncome = value.statedIncome || this.statedIncome
                this.$refs.addressField.loadAddressFormData(value.addressData)
            },
            getDocument: async function (docType) {
                try {
                    this.loadingTitle = 'Loading Document...'
                    this.loading = true
                    this.$logEvent('click_crypto_link_get_document', { docType })
                    await getDocumentForApplicantAndOpen(docType)
                } catch (e) {
                    logger.error(`failed to open pdfs document`, null /* event */, e)
                    this.errorText = ApiErrorHandler(e)
                }
                this.loading = false
                this.loadingTitle = i18n.t('global.loadingMessage.verifying')
            },
            identifyLogRocketUser: function () {
                try {
                    const sessionId = appSessionStorage.getItem(localStorageKey.sessionId)
                    LogRocket.identify(sessionId, {
                        name: `${this.firstName} ${this.lastName}`,
                        phone: this.phone,
                        email: this.email,
                    })
                } catch (error) {
                    logger.info(`LogRocket failed to identify: ${error}`)
                }
            },
        },
    }
</script>

<style scoped></style>
