<template>
    <div
        @click="onClick"
        class="income-selection-card"
    >
        <div
            v-if="badgeText"
            class="badge"
        >
            {{ badgeText }}
        </div>
        <img
            class="mb-2"
            :src="getImageSrc"
            width="100%"
            height="48px"
            :alt="imageAlt"
        >
        <div class="fw-bold text-center">
            {{ title }}
        </div>
        <div
            class="text-muted small text-center ms-1 me-1"
            v-html="description"
        />
    </div>
</template>

<script lang="js">
    export default {
        name: 'IncomeVerificationCard',
        props: {
            badgeText: {
                type: String,
                default: '',
            },
            imageSrc: {
                type: String,
                required: true,
            },
            imageAlt: {
                type: String,
                required: true,
            },
            title: {
                type: String,
                required: true,
            },
            description: {
                type: String,
                required: true,
            },
        },
        computed: {
            getImageSrc() {
                return require(`../assets/images/${this.imageSrc}`)
            },
        },
        methods: {
            onClick () {
                this.$emit('on-click')
            }
        },
    }
</script>
