<template>
    <onboarding-layout
        :title="title"
        :error-text="errorText"
        :active-step="3"
        step-title="Work Email"
    >
        <form-container
            id="employerEmailCodeVerificationForm"
            ref="form"
        >
            <form-field
                v-model="value"
                class="form-field mb-3"
                validation-mode="passive"
                validation-rules="required|length:3"
                name="employerEmail"
                ref="employerEmail"
                inputmode="decimal"
                :placeholder="$t('pages.origination.employerEmailCodeVerification.placeholder')"
                :label="$t('pages.origination.employerEmailCodeVerification.placeholder')"
            />

            <form-button
                :label="$t('pages.origination.employerEmailCodeVerification.cta')"
                class="mb-3"
                :block="true"
                :submitting="submitting"
                type="submit"
                @click="onSubmit"
                event-name="click_button_submit_employer_code_verify_form"
            />

            <div class="text-center">
                <div
                    class="text-dark fw-bold"
                    style="display: inline-block; cursor: pointer"
                    @click="onClickHelp"
                    v-html="$t('pages.origination.employerEmailCodeVerification.help')"
                />
            </div>
        </form-container>

        <email-verification-help-modal
            ref="emailVerificationHelpModal"
            @on-click-go-back="onSelectGoBack"
            @on-click-try-again="onSelectTryAgain"
        />
    </onboarding-layout>
</template>

<script lang="js">
    import OnboardingLayout from "@/layouts/Onboarding";
    import {verifyEmployerEmailCode} from "@/services/loanApplication";
    import FormField from "@/components/base/FormField"
    import FormContainer from "@/components/base/FormContainer";
    import FormButton from "@/components/base/FormButton";
    import {appSessionStorage, localStorageKey} from "@/utils/storage";
    import {ApiErrorHandler} from "@/utils/exception-handler";
    import {getNextRoute} from "@/flow/flowController";
    import {i18n} from "@/utils/i18n";
    import {logger} from "@/utils/logger";
    import originationMixin from "@/mixins/originationMixin";
    import EmailVerificationHelpModal from "@/components/modal/EmailVerificationHelpModal";
    import incomeVerificationMixin from "@/mixins/incomeVerificationMixin";

    export default {
        components: {
            "form-container": FormContainer,
            "form-field": FormField,
            "form-button": FormButton,
            "onboarding-layout": OnboardingLayout,
            EmailVerificationHelpModal,
        },
        mixins: [originationMixin, incomeVerificationMixin],
        data: function() {
            return {
                value: "",
            };
        },
        mounted: function() {
            if (appSessionStorage.getItem(localStorageKey.employerEmailVerificationCompleted) === "true") {
                logger.info('employer email already verified, moving on...')
                return this.$router.push(getNextRoute(this.$router))
            }
            this.$logEvent('view_employer_email_code_verification')
        },
        computed: {
            title: function() {
                const email = appSessionStorage.getItem(localStorageKey.employerEmail)
                if (!email) {
                    return i18n.t('pages.origination.employerEmailCodeVerification.defaultTitle')
                }
                return i18n.t('pages.origination.employerEmailCodeVerification.title', { email })
            },
        },
        methods: {
            onSubmit: async function() {
                if (this.submitting) { return; }
                this.submitting = true

                // Check if form is valid
                const isValid = await this.$refs.form.$refs.observer.validate()
                if (!isValid) { this.submitting = false; return; }

                try {
                    const response = await verifyEmployerEmailCode(this.value, this.isCoApplicantVerifying);
                    if (response.data.success === true) {
                        appSessionStorage.setItem(localStorageKey.incomeVerificationCompleted, "true")
                        return await this.$router.push(getNextRoute(this.$router))
                    } else {
                        logger.info(`incorrect employer verification code ${this.value}`)
                        this.errorText = i18n.tc('pages.origination.employerEmailCodeVerification.wrongCode')
                    }
                } catch (error) {
                    this.errorText = ApiErrorHandler(error)
                }
                this.submitting = false
            },
            onClickHelp: async function() {
                this.$refs.emailVerificationHelpModal.expand()
            },
            onSelectTryAgain: async function() {
                await this.$router.back()
            },
            onSelectGoBack: async function() {
                await this.$router.push(getNextRoute(this.$router))
            },
        }
    };
</script>
