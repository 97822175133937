<template>
    <onboarding-layout
        :loading="loading"
        :error-text="errorText"
        loading-title="Finalizing lookup..."
        data-testid="employment-income-verification-page"
    >
        <h5 class="fw-light mb-0">
            Let’s quickly verify <span class="fw-bold"> {{ currentApplicantName }}'s </span>
            employment and income
        </h5>
        <p class="text-muted mt-1">
            Unlike a traditional mortgage or HELOC, we use technology to shrink your process to a few minutes.
        </p>
        <form-container
            id="employerForm"
            ref="employerFormRef"
        >
            <div class="mt-3">
                <div class="row g-0">
                    <div class="col-6 custom-control custom-radio d-inline-flex custom-control-tight">
                        <input
                            type="radio"
                            id="customRadioInline1"
                            name="customRadioInline1"
                            class="custom-control-input"
                            :value="EmploymentType.employed"
                            v-model="employmentType"
                        >
                        <label
                            class="custom-control-label custom-control-label-tight"
                            for="customRadioInline1"
                            data-testid="employment-income-verification-employment-option"
                        >Employed</label>
                    </div>
                    <div class="col custom-control custom-radio custom-control-tight">
                        <input
                            type="radio"
                            id="customRadioInline2"
                            name="customRadioInline2"
                            class="custom-control-input"
                            :value="EmploymentType.selfEmployed"
                            v-model="employmentType"
                        >
                        <label
                            class="custom-control-label custom-control-label-tight"
                            for="customRadioInline2"
                        >Self Employed</label>
                    </div>
                </div>
                <div class="mt-2 row g-0">
                    <div class="col-6 custom-control custom-radio d-inline-flex custom-control-tight">
                        <input
                            type="radio"
                            id="customRadioInline3"
                            name="customRadioInline3"
                            class="custom-control-input"
                            :value="EmploymentType.retired"
                            v-model="employmentType"
                        >
                        <label
                            class="custom-control-label custom-control-label-tight"
                            for="customRadioInline3"
                        >Retired</label>
                    </div>
                    <div class="col custom-control custom-radio custom-control-tight">
                        <input
                            type="radio"
                            id="customRadioInline4"
                            name="customRadioInline4"
                            class="custom-control-input"
                            :value="EmploymentType.other"
                            v-model="employmentType"
                        >
                        <label
                            class="custom-control-label custom-control-label-tight"
                            for="customRadioInline4"
                        >Other</label>
                    </div>
                </div>
            </div>

            <div v-if="employmentType && employmentType !== EmploymentType.retired">
                <form-field
                    v-model="employer"
                    class="mt-3"
                    validation-mode="passive"
                    validation-rules="required|min:2"
                    name="employer"
                    ref="employer"
                    placeholder="Employer / Business Name"
                    label="Employer / Business Name"
                    data-testid="employment-income-verification-employer-input"
                />
            </div>
        </form-container>

        <div
            v-show="showIncomeVerificationCards && isCurrentApplicantVerifying"
            class="fade-in"
        >
            <div class="section-header text-center fw-bold mt-5">
                PICK ONE
            </div>
            <div
                class="text-center"
                style="font-size: 80%"
                v-if="isJointApplication && hasExtendedLinesOffer"
            >
                Be sure to upload documents for <span class="fw-bold">both</span> {{ primaryApplicantName }} and {{ secondaryApplicantName }}
            </div>
            <div class="row mt-3">
                <div class="col pe-1">
                    <income-verification-option-card
                        image-src="global/saving-bank.svg"
                        image-alt="bank"
                        title="Verify Bank"
                        description="Securely sign-in to your<br/>primary account"
                        badge-text="Fastest"
                        @on-click="onPlaidConnectClick"
                    />
                </div>
                <div class="col ps-1">
                    <income-verification-option-card
                        image-src="global/accounting-document.svg"
                        image-alt="accounting document"
                        title="Pay Stub"
                        description="Photo of your latest<br/>pay stub"
                        @on-click="redirectToPayStubsVerification"
                        data-testid="employment-income-verification-pay-stub-option"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col pe-1">
                    <income-verification-option-card
                        image-src="global/accounting-invoice-hand.svg"
                        image-alt="invoice hand"
                        title="Bank Statement"
                        description="Photo or PDF of your<br/>3 latest statements"
                        @on-click="redirectToBankStatementVerification"
                    />
                </div>
                <div class="col ps-1">
                    <income-verification-option-card
                        image-src="global/common-file-text-add.svg"
                        image-alt="tax document"
                        title="Tax Return"
                        description="Front page of your<br/>2020 tax return"
                        @on-click="redirectToTaxReturnVerification"
                    />
                </div>
            </div>
        </div>

        <div v-show="showContinueButton">
            <form-button
                class="mt-3"
                :label="$t('pages.origination.employer.cta')"
                :submitting="submitting"
                type="button"
                event-name="click_button_submit_employer_form"
                @click="handleClickContinue"
            />
        </div>

        <template #sidebar>
            <offer-info-card
                v-if="preQualificationOffer"
                title="Your PreQual Offer"
                :apr="preQualificationOffer.apr"
                :credit-limit="preQualificationOffer.lineSize"
            />
            <help-list-item />
        </template>
    </onboarding-layout>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import FormField from '@/components/base/FormField'
    import FormContainer from '@/components/base/FormContainer'
    import FormButton from '@/components/base/FormButton'
    import HelpListItem from '@/components/onboarding/HelpListItem'
    import { misc } from '@/mixins/misc'
    import originationMixin from '@/mixins/originationMixin'
    import { Flows, getNextRoute } from '@/flow/flowController'
    import { updateEmployer } from '@/services/loanApplication'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import OfferInfoCard from '@/components/onboarding/OfferInfoCard'
    import computePreQualificationOfferFromStorageMixin from '@/mixins/computePreQualificationOfferFromStorageMixin'
    import IncomeVerificationOptionCard from '@/components/IncomeVerificationOptionCard'
    import incomeVerificationMixin from '@/mixins/incomeVerificationMixin'
    import { RouteOption } from '@/flow/flowUtility'
    import { i18n } from '@/utils/i18n'
    import { logger } from '@/utils/logger'
    import { PlaidManager } from '@/mixins/plaidManager'
    import { getIsIncomeVerifiedRequest, runAvenCardOffer } from '@/services/offer'
    import { runWithRetryLogic } from '@/utils/http-client'
    import { startCase, toLower } from 'lodash'
    import { getIsWorkNumberIncomeValidated } from '../../services/offer'

    const EmploymentType = {
        employed: 'employed',
        selfEmployed: 'selfEmployed', // Must change python code if this word is changed
        retired: 'retired', // Must change python code if this word is changed
        other: 'other', // Must change python code if this word is changed
    }

    export default {
        name: 'EmploymentIncomeVerification',
        components: {
            'income-verification-option-card': IncomeVerificationOptionCard,
            'help-list-item': HelpListItem,
            'form-container': FormContainer,
            'form-field': FormField,
            'form-button': FormButton,
            'onboarding-layout': OnboardingLayout,
            'offer-info-card': OfferInfoCard,
        },
        props: {
            isCoApplicant: {
                type: Boolean,
                default: false,
            },
        },
        mixins: [misc, originationMixin, incomeVerificationMixin, computePreQualificationOfferFromStorageMixin],
        data: function () {
            return {
                loading: true,
                EmploymentType: EmploymentType,
                employmentType: null,
                employer: '',
                jobTitle: '',
                plaidManager: new PlaidManager(this.onPlaidSuccess, this.onNoDepositoryAccountFound, this.onPlaidExit),
                incomeVerificationCompleted: false,
                hasExtendedLinesOffer: false, // we currently only want to show bank verify to non-extended lines
            }
        },
        mounted: async function () {
            // this.isCoApplicant will be true or false depending on whether this page is being displayed
            // on the primary applicant income verification route or the co-applicant income verification
            // route:
            // originationPagePaths.EMPLOYMENT_INCOME_VERIFICATION
            // originationPagePaths.CO_APPLICANT_EMPLOYMENT_INCOME_VERIFICATION
            // In the co-applicant case, we'll mount this page whether or not the application has a co-applicant.
            // If it does, we'll continue with the rest of the logic below. If it does not, we'll navigate
            // to the next route, skipping co-applicant income verification.
            if (this.isCoApplicant && !this.hasCoApplicant) {
                logger.info(`Applicant is co-applicant, but application has no co-applicant (?!?!), navigating from income verification to next route`)
                return this.$router.replace(getNextRoute(this.$router))
            }

            appSessionStorage.setItem(localStorageKey.currentFlow, Flows.origination)
            const preQualFailureCode = appSessionStorage.getItem(localStorageKey.preQualificationFailureCode)
            if (preQualFailureCode) {
                logger.info(`Application has pre-qual failure code of ${preQualFailureCode}, navigating from income verification to next route`)
                return await this.$router.push(getNextRoute(this.$router))
            }

            try {
                // Check if we can skip this page entirely from pre-existing prequal work number income verification
                const isWorkNumberIncomeValidatedResponse = await getIsWorkNumberIncomeValidated()
                if (isWorkNumberIncomeValidatedResponse.data.payload.isWorkNumberIncomeValidated) {
                    logger.info('Work number income is already verified through prequal. Skipping income verification page and continuing on.')
                    return this.$router.replace(getNextRoute(this.$router))
                }

                const applicantSubmittedEmployer = appSessionStorage.getItem(localStorageKey.applicantSubmittedEmployer) === 'true'
                const coApplicantSubmittedEmployer = appSessionStorage.getItem(localStorageKey.coApplicantSubmittedEmployer) === 'true'
                const currApplicantSubmittedEmployer = !this.isCoApplicant ? applicantSubmittedEmployer : coApplicantSubmittedEmployer
                const submittedIncomeDocuments = appSessionStorage.getItem(localStorageKey.incomeVerificationCompleted) === 'true'

                if (this.isCurrentApplicantVerifying) {
                    logger.info('Current applicant is verifying income...')
                    if (submittedIncomeDocuments && currApplicantSubmittedEmployer) {
                        logger.info('Current applicant has already submitted income docs and submitted employer. Navigating to next route')
                        return this.$router.replace(getNextRoute(this.$router))
                    }

                    await this.initializePlaid()

                    this.incomeVerificationCompleted = await this.getIsIncomeVerified()
                    if (this.incomeVerificationCompleted && currApplicantSubmittedEmployer) {
                        logger.info('Current applicant has completed income verification and submitted employer. Navigating to next route')
                        return this.$router.replace(getNextRoute(this.$router))
                    }
                } else if (currApplicantSubmittedEmployer) {
                    logger.info('Current applicant has already submitted employer and is verified. Navigating to next route')
                    // primary or co applicant already submitted employer and is verified, go to next screen
                    return this.$router.replace(getNextRoute(this.$router))
                }

                this.hasExtendedLinesOffer = !!appSessionStorage.getItem(localStorageKey.hasExtendedLinesOffer)

                this.loading = false
                this.$logEvent('view_employment_income_verification')
            } catch (e) {
                this.errorText = ApiErrorHandler(e)
            }
        },
        computed: {
            isCurrentApplicantVerifying: function () {
                return this.isCoApplicant ? this.isCoApplicantVerifying : !this.isCoApplicantVerifying
            },
            completedEmployer: function () {
                return this.employmentType && ((this.employer.length > 2 && this.employmentType !== EmploymentType.retired) || this.employmentType === EmploymentType.retired)
            },
            showIncomeVerificationCards: function () {
                return !this.incomeVerificationCompleted && this.completedEmployer
            },
            showContinueButton: function () {
                return this.completedEmployer && (this.incomeVerificationCompleted || !this.isCurrentApplicantVerifying)
            },
            currentApplicantName() {
                if (this.isCoApplicant) {
                    return startCase(toLower(appSessionStorage.getItem(localStorageKey.coApplicantFirstName)))
                } else {
                    return startCase(toLower(appSessionStorage.getItem(localStorageKey.firstName)))
                }
            },
            isJointApplication() {
                return !!appSessionStorage.getItem(localStorageKey.coApplicantFirstName)
            },
            primaryApplicantName() {
                return startCase(toLower(appSessionStorage.getItem(localStorageKey.firstName)))
            },
            secondaryApplicantName() {
                return startCase(toLower(appSessionStorage.getItem(localStorageKey.coApplicantFirstName)))
            },
        },
        watch: {
            employer: function (value) {
                this.throttleLogging(value, 'aven applicant employer')
            },
        },
        methods: {
            getIsIncomeVerified: async function () {
                const startResponse = await runAvenCardOffer({ isPreQualification: false, purpose: 'incomeVerification' })
                const createdAfter = startResponse.data.payload.createdAfter

                // preview offer result
                let previewResponse
                for (let i = 0; i < 30; i++) {
                    try {
                        previewResponse = await runWithRetryLogic(async () => await getIsIncomeVerifiedRequest(createdAfter), 1)
                        break
                    } catch (e) {
                        if (e?.response?.status === 423) {
                            // HTTP 423 === "Locked"
                            logger.info(`Waiting on underwriting to complete. Attempt nr ${i + 1}`)
                        } else {
                            throw e
                        }
                    }
                    await new Promise((r) => setTimeout(r, 2000))
                }

                logger.info(`previewResponse: ${JSON.stringify(previewResponse?.data?.payload)}`)

                const { isIncomeVerified } = previewResponse.data.payload
                if (isIncomeVerified) {
                    appSessionStorage.setItem(localStorageKey.incomeVerificationCompleted, 'true')
                }
                return isIncomeVerified
            },
            submitEmployer: async function () {
                this.submitting = true

                // Check if form is valid
                const isValid = await this.$refs.employerFormRef.$refs.observer.validate()
                if (!isValid) {
                    this.submitting = false
                    return
                }

                // Set jobTitle to 'retired' if employer is 'retired'
                const employer = this.employmentType === EmploymentType.retired ? EmploymentType.retired : this.employer

                try {
                    // update applicant's employer and we set jobTitle to EmploymentType.*
                    this.jobTitle = this.employmentType
                    await updateEmployer(employer, this.jobTitle, this.isCoApplicant)

                    if (this.isCoApplicant) {
                        appSessionStorage.setItem(localStorageKey.coApplicantSubmittedEmployer, 'true')
                    } else {
                        appSessionStorage.setItem(localStorageKey.applicantSubmittedEmployer, 'true')
                    }
                } catch (e) {
                    this.errorText = ApiErrorHandler(e)
                }
                this.submitting = false
            },
            handleClickContinue: async function () {
                this.$logEvent('click_button_continue_employment_income_verification')
                await this.submitEmployer()
                return await this.$router.push(getNextRoute(this.$router))
            },
            redirectToPayStubsVerification: async function () {
                this.$logEvent('click_button_verify_income_with_pay_stub')
                await this.submitEmployer()
                return await this.$router.push(getNextRoute(this.$router, RouteOption.payStubsVerify))
            },
            redirectToTaxReturnVerification: async function () {
                this.$logEvent('click_button_verify_income_with_tax_return')
                await this.submitEmployer()
                return await this.$router.push(getNextRoute(this.$router, RouteOption.taxReturnVerify))
            },
            redirectToBankStatementVerification: async function () {
                this.$logEvent('click_button_verify_income_with_bank_statement')
                await this.submitEmployer()
                return await this.$router.push(getNextRoute(this.$router, RouteOption.bankStatementVerify))
            },
            initializePlaid: async function () {
                const plaidInitialized = await this.plaidManager.init()
                if (!plaidInitialized) {
                    this.errorText = i18n.tc('conversation.adBlockMessage')
                }
            },
            onPlaidConnectClick: async function () {
                this.$logEvent('click_button_verify_income_with_plaid')
                await this.submitEmployer()
                this.errorText = '' // clear error message
                this.plaidManager.open()
            },
            onPlaidSuccess: async function (plaidPublicToken, accounts, institutionInfo) {
                logger.info('connected to bank account, trying to fetch plaid report...')
                this.loadingTitle = i18n.t('bankConnect.loadingContent')
                this.loading = true
                try {
                    const plaidCompletionSuccess = await this.plaidManager.completePlaidFetch(plaidPublicToken, accounts, institutionInfo, this.isCoApplicant)
                    if (plaidCompletionSuccess) {
                        if (await this.getIsIncomeVerified()) {
                            logger.info('User qualifies without further income verification... Forwarding towards offer page!')
                            return await this.$router.push(getNextRoute(this.$router))
                        } else {
                            this.loading = false
                            this.loadingTitle = i18n.t('bankConnect.loading')
                            this.errorText = i18n.tc('bankConnect.insufficientIncome')
                            return
                        }
                    }
                } catch (e) {
                    ApiErrorHandler(e)
                }
                this.loading = false
                this.loadingTitle = i18n.t('bankConnect.loading')
                this.errorText = i18n.tc('bankConnect.tryAgain')
            },
            onNoDepositoryAccountFound: function (bankName) {
                logger.info('Error: Bank has no depository account')
                this.errorText = i18n.tc('conversation.noDepositoryAccount', null, { bankName })
            },
            // eslint-disable-next-line no-unused-vars
            onPlaidExit: function (error, metadata) {
                this.errorText = i18n.tc('bankConnect.tryAgain')
            },
        },
    }
</script>

<style lang="scss">
    @import '../../styles/components/income-selection-card.scss';
</style>
