<template>
    <div>
        <validation-provider
            mode="eager"
            v-slot="{ errors }"
            :vid="name"
            ref="provider"
            :rules="validationRules"
            class="form-floating"
        >
            <v-select
                autocomplete="new-password"
                :options="filteredData"
                :filterable="false"
                :id="name"
                :class="{ 'is-invalid': errors[0], 'is-valid': valid }"
                :name="name"
                :placeholder="placeholder"
                :loading="loading"
                :no-drop="noDrop"
                @search="onSearchUpdate"
                v-model="currentValue"
            >
                <template #spinner="{ loading }">
                    <div
                        v-if="loading"
                        class="vs__spinner spinner-border spinner-border-sm"
                    />
                </template>

                <template #selected-option="data">
                    <div>
                        <span class="selected-address">{{ data.label }}</span>
                    </div>
                </template>

                <template #search="{ attributes, events }">
                    <input
                        ref="searchInput"
                        class="vs__search"
                        v-bind="attributes"
                        v-on="events"
                        @input="$emit('input-value-changed', $event.target.value)"
                    >
                </template>

                <template
                    #list-footer
                    v-if="noResultsFooterText"
                >
                    <div @click="onNoResultsFooterClicked">
                        <p class="my-1 text-info text-center">
                            {{ noResultsFooterText }}
                        </p>
                    </div>
                </template>

                <template #no-options="{ search, searching }">
                    <template v-if="searching && !noDrop">
                        {{ $t('components.formSelectAutocomplete.noOptions') }} <em>{{ search }}</em>.
                    </template>
                </template>
            </v-select>

            <label
                v-if="label"
                :for="name"
            >{{ label }}</label>

            <div class="invalid-feedback">
                {{ errors[0] }}
            </div>
        </validation-provider>
    </div>
</template>

<script>
    /**
 * Use for all text input fields. Has label, validation, placeholder and filters.

 * @prop{String} name (required) To modify the text formatting. Can be 'uppercase|lowercase'.
 * @prop{Array} data (required) Array of options.
 *
 * @prop{String} label (optional) A label above the input.
 * @prop{String} placeholder (optional) The text when the input is blank.
 */

    import { ValidationProvider } from 'vee-validate'
    import vSelect from 'vue-select'

    export default {
        name: 'FormSelectAutocomplete',
        props: {
            value: String,
            validationRules: { type: String, default: 'required' },
            name: { type: String, required: true },
            data: { type: Array, required: true },
            filterable: { type: Boolean, default: true },
            classes: String,
            label: String,
            placeholder: String,
            loading: { type: Boolean, default: false },
            noResultsFooterText: { type: String, default: null },
            noDrop: { type: Boolean, default: false },
        },
        components: {
            'validation-provider': ValidationProvider,
            'v-select': vSelect,
        },
        data() {
            return {
                currentValue: '',
                valid: true,
                filteredData: [],
            }
        },
        created() {
            this.currentValue = this.value
            this.onSearchUpdate('')
        },
        watch: {
            currentValue: function (value) {
                this.$refs.provider.validate()
                this.$emit('input', value)
            },
        },
        methods: {
            onSearchUpdate(value) {
                const filtered = this.data.filter((item) => item.toLowerCase().includes(value.toLowerCase()))
                this.filteredData = filtered.slice(0, 50)
            },
            onNoResultsFooterClicked() {
                this.$emit('on-no-results-footer-clicked')
            },
            applyError(errorMessage) {
                this.$refs.provider.applyResult({
                    errors: [errorMessage], // array of string errors
                    valid: false, // boolean state
                    failedRules: {}, // should be empty since this is a manual error.
                })
            },
            updateValue(value) {
                this.currentValue = value
            },
        },
    }
</script>

<style lang="scss">
    @import '../../styles/components/vueSelect/vueSelect';
    @import '../../styles/components/base/formSelectAutocomplete';
</style>
