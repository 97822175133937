<template>
    <onboarding-layout
        :title="title"
        :loading="loading"
        :loading-title="$t('global.loadingMessage.verifying')"
        :error-text="errorText"
        :show-title-bar="false"
    >
        <form-container
            ref="form"
            @on-submit="onSubmit"
        >
            <div
                v-for="(data, index) in questionData"
                :key="index"
            >
                <form-radio-group
                    class="mb-5"
                    :name="data.type"
                    :prompt="data.prompt"
                    :options="data.answer"
                    v-model="answerData[index]"
                />
            </div>

            <form-button
                class="mt-3"
                :label="$t('global.cta.continue')"
                type="submit"
                event-name="click_button_submit_identity_questions_form"
            />
        </form-container>
    </onboarding-layout>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import FormRadioGroup from '@/components/base/FormRadioGroup'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import { postChallengeQuestionAnswers, postQuestionAnswers, getIDologyChallengeQuestions } from '@/services/idology'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { logger } from '@/utils/logger'
    import { getNextRoute } from '@/flow/flowController'
    import { sharedPagePaths } from '@/routes/sharedRoutes'
    import originationMixin from '@/mixins/originationMixin'
    import { isAndroid, isAndroidBrowserOrChrome, isIOS, isIOSChromeFF, isIOSSafari } from '@/utils/parseUserAgents'
    import FormButton from '@/components/base/FormButton'
    import FormContainer from '@/components/base/FormContainer'
    import { i18n } from '@/utils/i18n'
    import { startCase, toLower } from 'lodash'
    import assert from 'assert'

    const VerificationState = {
        IDENTITY_QUESTIONS: 'identityQuestions',
        CHALLENGE_QUESTIONS: 'challengeQuestion',
    }

    export default {
        components: {
            'form-container': FormContainer,
            'onboarding-layout': OnboardingLayout,
            'form-radio-group': FormRadioGroup,
            'form-button': FormButton,
        },
        props: {
            isCoApplicant: { type: Boolean, default: false },
        },
        mixins: [originationMixin],
        data() {
            return {
                ssn: '',
                loading: true,
                idologyData: null,
                questions: [],
                questionData: [],
                answerData: [],
                isChallengeQuestion: false,
                state: VerificationState.IDENTITY_QUESTIONS,
            }
        },
        computed: {
            title() {
                if (this.isCoApplicant) {
                    // Co-Applicant
                    const firstName = startCase(toLower(appSessionStorage.getItem(localStorageKey.coApplicantFirstName)))
                    return i18n.t('pages.identityQuestions.titleWithCoApplicant', { firstName })
                } else if (appSessionStorage.getItem(localStorageKey.coApplicantJwtTokens)) {
                    // Primary applicant with co-applicant
                    const firstName = startCase(toLower(appSessionStorage.getItem(localStorageKey.firstName)))
                    return i18n.t('pages.identityQuestions.titleWithCoApplicant', { firstName })
                } else {
                    // Individual applicant
                    return i18n.t('pages.identityQuestions.title')
                }
            },
        },
        mounted: async function () {
            try {
                logger.info(`history length: ${window.history.length}`)
                this.idologyData = JSON.parse(appSessionStorage.getItem(localStorageKey.identityQA))
                if (!this.idologyData) {
                    logger.warn('user has no idology questions, sending to sorry page')
                    return await this.$router.replace(sharedPagePaths.THANKS)
                }
                this.questionData = this.idologyData.questions
                this.loading = false
                this.$logEvent('view_identity_questions')
            } catch (e) {
                this.errorText = ApiErrorHandler(e)
            }
        },
        methods: {
            redirectIfUnsupportedBrowser: async function () {
                logger.log('Checking browser / OS type... ' + window.navigator.userAgent)

                if (isIOS()) {
                    logger.info('User is on an iOS device')

                    if (isIOSChromeFF() || !isIOSSafari()) {
                        logger.info('Need to redirect to user from criOS / other to safari')
                        await this.$router.push({ path: sharedPagePaths.NOTARY_REQUIRES_SAFARI })
                        return true
                    } else {
                        logger.info('User is on regular iOS Safari')
                    }
                } else {
                    logger.info('User is not on iOS')
                }

                if (isAndroid()) {
                    logger.info('User is on an android device')
                    if (!isAndroidBrowserOrChrome()) {
                        logger.info('User is using a non-stock android browser, redirecting')
                        await this.$router.push({ path: sharedPagePaths.NOTARY_REQUIRES_CHROME })
                        return true
                    } else {
                        logger.info('User is on regular stock android browser / chrome')
                    }
                } else {
                    logger.info('User is not on Android')
                }

                return false
            },
            onSubmit: async function () {
                try {
                    if (this.loading) {
                        console.log('Still loading, refusing to submit')
                        return
                    }

                    const isValid = await this.$refs.form.$refs.observer.validate()
                    if (!isValid) {
                        const el = document.querySelector('.is-invalid:first-of-type')
                        el?.scrollIntoView()
                        return
                    }

                    this.loading = true

                    if (this.state === VerificationState.IDENTITY_QUESTIONS) {
                        await this.submitIdentityQuestionAnswers()
                    } else if (this.state === VerificationState.CHALLENGE_QUESTIONS) {
                        await this.submitChallengeAnswers()
                    }
                } catch (e) {
                    this.errorText = ApiErrorHandler(e)
                    this.loading = false
                }
            },
            submitIdentityQuestionAnswers: async function () {
                appSessionStorage.removeItem(localStorageKey.identityQA)

                let response
                const questionPayload = []
                for (let i = 0; i < this.questionData?.length; i++) {
                    questionPayload.push({
                        questionType: this.questionData[i].type,
                        answer: this.answerData[i],
                    })
                }

                const payload = {
                    answers: questionPayload,
                }
                response = await postQuestionAnswers(payload, this.idologyData.idologyQueryId, this.isCoApplicant)

                logger.info(`ExpectID Answer verification response: ${JSON.stringify(response.data)}`)

                // Incomplete application is actually a euphemism for "you failed and can't retry"
                if (response.data.error === 'INCOMPLETE_APPLICATION') {
                    logger.info(`verifyIdentity error: ${response.data.error} -> presenting ${sharedPagePaths.THANKS}`)
                    return await this.$router.replace({ path: sharedPagePaths.THANKS, query: { reason: 'identityFailure' } })
                } else if (response.data.error === 'PRIOR_APPLICATION_FOUND') {
                    assert(response.data.payload.returnToken2, 'no return token provided for prior loan application')
                    logger.info(`Found prior loan application, using return token ${response.data.payload.returnToken2}`)
                    const nextPath = response.data.payload.isPriorityApplication ? sharedPagePaths.PRIORITY_APPLICATION_REDIRECTION : sharedPagePaths.PROMPT_CONTINUE_PRIOR_APPLICATION
                    const pathAfterNextOne = getNextRoute(this.$router)
                    return await this.$router.push({
                        path: nextPath,
                        query: {
                            p: response.data.payload.returnToken2,
                            a: pathAfterNextOne,
                            c: this.isCoApplicant,
                            s: response.data.payload.priorStatus,
                        },
                    })
                } else if (!response.data.success) {
                    // This is a temporary state than may be resolved by retrying KBA
                    logger.info(`verifyIdentity error: ${response.data.error} and status: ${response.data.payload?.status} -> presenting ${sharedPagePaths.UNABLE_TO_VERIFY_IDENTITY}`)
                    return await this.$router.replace(sharedPagePaths.UNABLE_TO_VERIFY_IDENTITY)
                }

                const status = response.data?.payload?.status
                if (status === 'pass') {
                    // answered all questions correctly
                    this.$logEvent('event_passed_identity_questions')
                    logger.info('Received pass, moving on to rest of the flow')
                    appSessionStorage.setItem(localStorageKey.verifiedKba, 'true')
                    await this.$router.replace(getNextRoute(this.$router))
                } else if (status === 'allowableFail') {
                    this.$logEvent('event_identity_verification_failed', { status })
                    logger.info('Received allowable fail, moving on to rest of the flow')
                    appSessionStorage.setItem(localStorageKey.verifiedKba, 'true')
                    await this.$router.replace(getNextRoute(this.$router))
                } else if (status === 'challenge') {
                    //one wrong answer, get challenge question
                    this.$logEvent('event_getting_identity_challenge_questions')
                    logger.info(`Get Challenge questions`)
                    this.isChallengeQuestion = true
                    await this.getIDologyChallengeQuestions()
                    this.loading = false
                } else {
                    this.$logEvent('event_identity_verification_failed', { status })
                    logger.info(`Catching unanticipated response data payload after submitting identity question answers with a status of: ${response.data.payload.status}`)
                    await this.$router.replace(sharedPagePaths.THANKS)
                }
            },
            getIDologyChallengeQuestions: async function () {
                this.state = VerificationState.CHALLENGE_QUESTIONS
                const response = await getIDologyChallengeQuestions(this.isCoApplicant)
                logger.info(`Challenge Questions: ${JSON.stringify(response.data)}`)

                const status = response.data?.status || 'na'
                if (status === 'pass') {
                    logger.info(`Got challenge questions, showing to user`)
                    this.questionData = response.data.questions
                } else if (status === 'allowableFail') {
                    this.$logEvent('event_identity_verification_failed', { status })
                    appSessionStorage.setItem(localStorageKey.verifiedKba, 'true')
                    logger.info('Received allowable fail, moving on to rest of the flow')
                    await this.$router.replace(getNextRoute(this.$router))
                } else {
                    this.$logEvent('trigger_manual_identity_review')
                    this.$logEvent('event_identity_verification_failed', { status })
                    logger.info(`Catching unanticipated response data payload after submitting identity question answers with a status of: ${status}`)
                    await this.$router.replace(sharedPagePaths.THANKS)
                }
            },
            submitChallengeAnswers: async function () {
                const payload = []
                payload.push({
                    questionType: this.questionData[0].type,
                    answer: this.answerData[0],
                })

                const response = await postChallengeQuestionAnswers(payload, this.idologyData.idologyQueryId, this.isCoApplicant)
                logger.info(`Challenge Answer verification response: ${JSON.stringify(response.data)}`)
                if (response.data.error === 'INCOMPLETE_APPLICATION') {
                    logger.info(`verifyIdentity error: ${response.data.error} -> presenting ${sharedPagePaths.THANKS}`)
                    return await this.$router.replace({ path: sharedPagePaths.THANKS, query: { reason: 'identityFailure' } })
                } else if (response.data.error === 'PRIOR_APPLICATION_FOUND') {
                    assert(response.data.payload.returnToken2, 'no return token provided for prior loan application')
                    logger.info(`Found prior loan application, using return token ${response.data.payload.returnToken2}`)
                    const nextPath = response.data.payload.isPriorityApplication ? sharedPagePaths.PRIORITY_APPLICATION_REDIRECTION : sharedPagePaths.PROMPT_CONTINUE_PRIOR_APPLICATION
                    const pathAfterNextOne = getNextRoute(this.$router)
                    return await this.$router.push({
                        path: nextPath,
                        query: {
                            p: response.data.payload.returnToken2,
                            a: pathAfterNextOne,
                            c: this.isCoApplicant,
                            s: response.data.payload.priorStatus,
                        },
                    })
                }

                const status = response.data?.payload?.status
                if (response.data.success) {
                    this.$logEvent('event_passed_identity_questions')
                    logger.info('Received pass, moving on to the rest of the flow')
                    appSessionStorage.setItem(localStorageKey.verifiedKba, 'true')
                    await this.$router.replace(getNextRoute(this.$router))
                } else if (status === 'allowableFail') {
                    this.$logEvent('event_identity_verification_failed', { status })
                    logger.info('Received allowable fail, moving on to the rest of the flow')
                    appSessionStorage.setItem(localStorageKey.verifiedKba, 'true')
                    await this.$router.replace(getNextRoute(this.$router))
                } else {
                    logger.info(`verifyIdentity failed: ${response.data.error} and status: ${response.data.payload?.status} -> presenting ${sharedPagePaths.UNABLE_TO_VERIFY_IDENTITY}`)
                    await this.$router.replace(sharedPagePaths.UNABLE_TO_VERIFY_IDENTITY)
                }
            },
        },
    }
</script>
