<template>
    <div class="mb-2 form-field-address-autocomplete-container">
        <div
            class="mb-2"
            v-click-outside="onClickOutside"
        >
            <validation-provider
                mode="passive"
                vid="addressInput"
                name="addressInput"
                ref="addressInputProvider"
                v-slot="{ errors }"
            >
                <div class="input">
                    <div class="input-group form-floating">
                        <!-- TODO: debug this accessibility issue -->
                        <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                        <input
                            class="form-control"
                            type="text"
                            ref="addressInput"
                            id="addressInput"
                            name="addressInput"
                            data-testid="address-autocomplete-line-1-input"
                            autocomplete="new-password"
                            :placeholder="$t('components.formFieldAddress.placeholder.completeAddress')"
                            :class="errors[0] ? 'is-invalid' : null"
                            @focus="onFocus"
                            @blur="onBlur"
                            @keydown.down.prevent="onSelectDown"
                            @keydown.up.prevent="onSelectUp"
                            @keydown.enter.prevent="onSelectAddress"
                            @keydown.tab.prevent="onSelectAddress"
                            v-model="query"
                        >
                        <label for="addressInput"> Street, City, State </label>
                        <span
                            v-show="showLoading"
                            class="spinner spinner-border text-primary spinner-border-sm"
                        />
                        <span
                            v-show="showClear"
                            @click.prevent="onClear"
                            class="clear"
                        >
                            <img
                                src="../../assets/images/components/forms/clear.png"
                                width="24"
                                height="24"
                                alt="clear"
                            >
                        </span>
                        <div
                            v-if="!showDropdown"
                            class="invalid-feedback"
                        >
                            {{ errors[0] }}
                        </div>
                    </div>
                </div>
            </validation-provider>
            <div class="address-results-container">
                <div
                    v-if="showDropdown"
                    class="results"
                >
                    <ul>
                        <li
                            v-for="(prediction, index) in predictions"
                            :data-testid="'address-autocomplete-prediction-' + index + '-option'"
                            @click="onSelectItem(predictions[index], index)"
                            @keydown.enter="onSelectItem(predictions[index], index)"
                            :class="{ selected: index === highlightedIndex }"
                            :key="`${index}`"
                        >
                            {{ descriptionFromPrediction(prediction) }}
                        </li>
                        <li
                            v-if="predictions.length <= 0"
                            @click="useManualInput"
                            class="text-center text-info"
                        >
                            {{ $t(`components.formFieldAddress.enterAddressManually`) }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div>
            <form-field
                ref="secondaryAddressInput"
                name="addressUnit"
                autocomplete="new-password"
                :placeholder="$t('components.formFieldAddress.placeholder.unit')"
                :label="$t('components.formFieldAddress.placeholder.unit')"
                v-model="addressUnit"
            />
        </div>
    </div>
</template>

<script>
    import ClickOutside from 'vue-click-outside'
    import debounce from 'lodash/debounce'
    import { ValidationProvider } from 'vee-validate'
    import assert from 'assert'
    import FormField from '@/components/base/FormField'
    import { logger } from '@/utils/logger'
    import { i18n } from '@/utils/i18n'
    import { isSmallScreen } from '@/mixins/deviceMixin'
    import { misc } from '@/mixins/misc'
    import throttleMixin from '@/mixins/throttleMixin'
    import { SmartyStreetClient } from '@/services/smarty'

    /*
    data cycle
    user input/cached input
    --> query via Google places autocomplete
    --> prediction list --> for each prediction, the description is displayed in dropdown

    user selects a prediction, selectedPrediction is set using highlighted index.
    since highlighted index is initialized to 0,
    enter or tab or click out side the component selects the 1st prediction
    --> geocoder lookup using selectedPrediction.place_id
    --> geocoder.results[0].addressComponents is parsed and validated.
        if result is valid then data is passed back to parent component
        else error is displayed

    initialValue structure and output object structure
        {
            addressComponents: {
                addressStreet: string,
                addressUnit: string,
                addressCity: string,
                addressState: string, //2-letter abbr
                addressPostalCode: string
                country: 'USA'
            },
            query: string
            inputType: {'automatic' | 'manual}
        }
    */
    export default {
        name: 'FormAddressInput',
        components: {
            'form-field': FormField,
            'validation-provider': ValidationProvider,
        },
        mixins: [isSmallScreen, misc, throttleMixin],
        props: {
            initialValue: { type: Object, required: false },
        },
        data() {
            return {
                //Google Service
                smartyStreetClient: null,
                //UI state
                isDirty: false,
                hasFocus: false,
                loading: false,
                highlightedIndex: 0,

                // user input
                query: '',
                addressUnit: '',
                // prediction results from google
                predictions: [],
                // selected prediction
                selectedPrediction: null,
                validatedAddress: null,
                addressValidationError: null,
            }
        },
        created() {
            this.smartyStreetClient = new SmartyStreetClient()
        },
        mounted() {
            this.validateInitialValue()
        },
        watch: {
            query(value) {
                this.throttleLogging(value, 'address input')
                if (!value) {
                    this.loading = false
                    this.predictions = []
                    return
                }
                logger.info(`query: ${value}`)
                logger.info(`initial value: ${this.selectedPrediction ? this.descriptionFromPrediction(this.selectedPrediction) : ''}`)
                logger.info(`validated address: ${this.validatedAddress ? this.validatedAddress.descriptionWithNoSecondaryAddress() : ''}`)

                if (this.validatedAddress && this.validatedAddress.descriptionWithNoSecondaryAddress().toLowerCase() === value.toLowerCase()) {
                    logger.info(`input ignored. validatedAddress same as query, ${this.validatedAddress.descriptionWithNoSecondaryAddress().toLowerCase()} === ${value.toLowerCase()}`)
                    return
                }
                if (this.selectedPrediction && this.descriptionFromPrediction(this.selectedPrediction).toLowerCase() === value.toLowerCase()) {
                    logger.info(`input ignored. selectedPrediction same as query, ${this.descriptionFromPrediction(this.selectedPrediction).toLocaleLowerCase()} === ${value.toLowerCase()}`)
                    return
                }

                this.clearErrorMessage()
                this.selectedPrediction = null
                this.validatedAddress = null
                this.highlightedIndex = 0
                this.hasFocus = true
                this.debounceAddressInputChanged(value, this)
                this.$emit('on-clear')
            },
            addressUnit(value) {
                if (this.selectedPrediction) {
                    this.debounceSecondaryAddressChanged(value, this)
                }
            },
        },
        computed: {
            showLoading() {
                return this.loading && this.hasFocus
            },
            showDropdown() {
                return this.hasFocus && this.query.length > 0 && !this.selectedPrediction && this.isDirty
            },
            showClear() {
                return this.hasFocus && this.query.length > 0 && !this.loading
            },
        },
        methods: {
            onSelectDown(e) {
                e.preventDefault()
                this.highlightedIndex = (this.highlightedIndex + 1) % this.predictions.length
            },
            onSelectUp(e) {
                e.preventDefault()
                this.highlightedIndex = (this.highlightedIndex - 1) % this.predictions.length
            },
            onSelectAddress() {
                logger.info(`onSelectAddress`)
                this.loading = false
                this.hasFocus = false
                if (this.highlightedIndex < this.predictions.length) {
                    this.onSelectItem(this.predictions[this.highlightedIndex])
                }
                this.$refs.addressInput.blur()
            },
            onClear() {
                this.query = ''
                this.validatedAddress = null
                this.selectedPrediction = null
                this.addressValidationError = null
                this.addressUnit = ''
                this.clearErrorMessage()
                this.$refs.addressInput.focus()
                this.$emit('on-clear')
            },
            onInput() {
                this.throttleAnalytics('event_field_interaction', {
                    type: 'input',
                    value: this.value,
                    name: this.name,
                })
            },
            onFocus() {
                this.hasFocus = true
                if (this.isSmallScreen()) {
                    this.$refs.addressInput.scrollIntoView({ behavior: 'smooth' })
                }
            },
            onBlur() {
                setTimeout(() => {
                    this.validateUserInput()
                    this.$logEvent('event_field_interaction', {
                        type: 'blur',
                        value: this.query || '',
                        name: 'addressInput',
                    })
                }, 250)
            },
            onClickOutside() {
                this.hasFocus = false
            },
            debounceAddressInputChanged: debounce((value, context) => {
                context.getPlacePredictions(value)
            }, 200),
            getPlacePredictions: async function (value) {
                logger.info(`Retrieving predictions with input ${value}`)
                this.loading = true
                this.isDirty = true
                try {
                    this.predictions = (await this.smartyStreetClient.getPredictions(value)) || []
                    logger.info(`${JSON.stringify(this.predictions, null, 2)}`)
                } catch (error) {
                    logger.error(`smarty autocomplete error:`, null /* event */, error)
                } finally {
                    this.loading = false
                }
            },
            onSelectItem: async function (prediction, index) {
                this.selectedPrediction = prediction
                logger.info(`clicked prediction at index ${index}: ${JSON.stringify(this.selectedPrediction)}`)
                await this.validateAddress(this.selectedPrediction, this.addressUnit)
            },
            debounceSecondaryAddressChanged: debounce((value, context) => {
                context.validateAddress(context.selectedPrediction, value)
            }, 200),
            validateAddress: async function (prediction, addressUnit) {
                assert(prediction, 'no prediction provided, check implementation')
                try {
                    this.loading = true
                    const result = await this.smartyStreetClient.validateAddress(prediction, addressUnit)

                    if (result.addressValidationError) {
                        this.validatedAddress = null
                        this.addressValidationError = result.addressValidationError
                        this.query = this.descriptionFromPrediction(this.selectedPrediction)
                        if (this.addressValidationError.missingSecondaryAddress()) {
                            this.setSecondaryUnitErrorMessage('Please enter unit number')
                            this.$refs.secondaryAddressInput.setFocus()
                        } else if (this.addressValidationError.invalidSecondaryAddress()) {
                            this.setSecondaryUnitErrorMessage(this.addressValidationError.message)
                        } else {
                            this.setAddressErrorMessage(this.addressValidationError.message)
                        }
                        logger.warn(`Address validation error, selected prediction: ${JSON.stringify(prediction)}, error: ${this.addressValidationError.description()}`)
                        this.$emit('on-clear')
                    } else {
                        this.validatedAddress = result
                        logger.info(
                            `address validation successful, selected prediction: ${JSON.stringify(this.selectedPrediction)}, address unit: ${this.addressUnit}, validated address: ${JSON.stringify(
                                this.validatedAddress
                            )}`
                        )
                        this.addressValidationError = null
                        this.query = this.validatedAddress.descriptionWithNoSecondaryAddress()
                        this.addressUnit = this.validatedAddress.addressUnit || ''
                        this.$emit('on-change', {
                            inputType: 'automatic',
                            query: this.validatedAddress.descriptionWithNoSecondaryAddress(),
                            addressComponents: this.validatedAddress,
                        })
                        this.clearErrorMessage()
                    }
                } catch (error) {
                    this.setAddressErrorMessage('Unable to validate. Please try entering again')
                } finally {
                    this.loading = false
                }
            },
            validateUserInput() {
                // address validated by smarty street with no errors
                if (this.validatedAddress && !this.addressValidationError) {
                    this.clearErrorMessage()
                    return true
                }

                // didn't select any prediction results
                if (!this.selectedPrediction) {
                    this.setAddressErrorMessage(this.query ? 'Invalid Address' : 'Please enter an address')
                    // missing or invalid secondary
                } else if (this.addressValidationError && this.addressValidationError.invalidSecondaryAddress()) {
                    this.setSecondaryUnitErrorMessage(this.addressValidationError.message)
                    // didn't input any secondary
                } else if (this.addressValidationError && this.addressValidationError.missingSecondaryAddress()) {
                    this.setSecondaryUnitErrorMessage('Please enter unit number')
                }
                this.$emit('on-clear')
                return false
            },
            useManualInput() {
                this.$emit('use-manual-input')
            },
            validateInitialValue: async function () {
                const addressComponents = this.initialValue
                if (!addressComponents) {
                    logger.info('No initial address components received')
                    return
                }
                /*
                    construct validation payload, SmartySuggestion, from initialValue.addressComponents
                    {
                        addressStreet: string,
                        addressCity: string,
                        addressState: string,
                        addressPostalCode: string,
                        addressUnit: string
                    }

                    export interface SmartySuggestion {
                        street_line: string
                        secondary: string
                        city: string
                        state: string
                        zipcode: string
                    }

                    */
                let payloadAddress = {
                    street_line: addressComponents.addressStreet,
                    city: addressComponents.addressCity,
                    state: addressComponents.addressState,
                    zipcode: addressComponents.addressPostalCode,
                }
                logger.info(`initial address components: ${JSON.stringify(payloadAddress, null)}`)
                this.selectedPrediction = payloadAddress
                await this.validateAddress(payloadAddress, addressComponents.addressUnit)

                if (!this.validatedAddress) {
                    logger.warn(`initial address validation failed. not pre-filling input. ${JSON.stringify(payloadAddress)}`, this.addressValidationError || '')
                    this.selectedPrediction = null
                    this.addressValidationError = null
                    this.clearErrorMessage()
                }
            },
            clearErrorMessage() {
                this.setSecondaryUnitErrorMessage()
                this.setAddressErrorMessage()
            },
            setAddressErrorMessage(messageKey) {
                // this is invoked after async request.
                // if "Enter Address Manually" has been selected in the mean time then
                // addressInputProvider is not longer in the dom
                // https://sentry.io/organizations/heracles-corp/issues/2414539829/?project=2443271
                if (this.$refs.addressInputProvider) {
                    this.$refs.addressInputProvider.applyResult({
                        errors: messageKey ? [i18n.t(messageKey)] : [], // array of string errors
                        valid: false, // boolean state
                        failedRules: {}, // should be empty since this is a manual error.
                    })
                }
            },
            setSecondaryUnitErrorMessage(message) {
                this.$refs.secondaryAddressInput.applyError(message)
            },
            descriptionFromPrediction(prediction) {
                if (!prediction) {
                    return ''
                }

                return `${prediction['street_line']}, ${prediction['city']}, ${prediction['state']} ${prediction['zipcode']}`
            },
        },
        directives: {
            ClickOutside,
        },
    }
</script>

<style lang="scss" scoped>
    @import '../../styles/components/base/formField';
    @import '../../styles/components/base/formFieldAddressAutocomplete';
</style>
